<template>
  <div class="container">
    <div class="header">
      <div class="lang">
        <lang />
      </div>
    </div>
    <el-container style="height: auto">
      <el-main class="list-main-container">
        <div class="form-box">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="logo-box">
                <img src="@/assets/logo_big.png" alt="" />
              </div>
            </el-col>
            <el-col :span="12" :offset="0">
              <div class="login-container">
                <p class="login-box">{{ langObj.login.title }}</p>
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleFormRef"
                  label-width="1.25rem"
                  class="demo-ruleForm"
                  :label-position="'left'"
                >
                  <!-- <el-form-item
                    :label="langObj.login.auth_mannge"
                    prop="auth"
                  >
                    <el-select
                      v-model="ruleForm.auth"
                      placeholder="所属栏目"
                      style="width: 100%"
                    >
                      <el-option
                        :label="langObj.login.auth_name[0]"
                        :value="1"
                      ></el-option>

                    </el-select>
                  </el-form-item> -->
                  <el-form-item
                    :label="langObj.login.user_name"
                    prop="username"
                  >
                    <el-input v-model="ruleForm.username"></el-input>
                  </el-form-item>
                  <el-form-item
                    :label="langObj.login.pass_word"
                    prop="password"
                  >
                    <el-input
                      v-model="ruleForm.password"
                      show-password
                    ></el-input>
                  </el-form-item>
                  <el-form-item :label="langObj.login.v_code">
                    <!-- <el-col :span="11" style="padding: 0">
                      <el-input v-model="ruleForm.validateCode"></el-input>
                    </el-col>

                    <el-col
                      :span="9"
                      style="position: relative; line-height: 0"
                    >
                      <ValidateCode></ValidateCode>
                    </el-col> -->
                    <el-col :span="2">
                      <el-checkbox v-model="isPassword" style="color: #fff">{{
                        langObj.login.re_password
                      }}</el-checkbox>
                    </el-col>
                  </el-form-item>
                  <div class="button-box">
                    <el-button type="primary" @click="submitForm">{{
                      langObj.login.login
                    }}</el-button>
                    <el-button @click="resetForm">{{
                      langObj.login.reset
                    }}</el-button>
                  </div>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <div></div>
    </el-container>
  </div>
</template>

<script setup>
import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
// import ValidateCode from "@/components/ValidateCode/index.vue";

import lang from "@/components/header/lang.vue";
import { formStateObj } from "@/hooks/login/formState.js"; //定义 ruleFrom rules
import { systemLogin } from "@/common/api/index/api.js";
import VueCookies from "vue-cookies";
import AES from "@/common/AES.js";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import md5 from "js-md5";
const { ruleForm, rulesObj, isPassword } = formStateObj();
const ruleFormRef = ref(null);
let rules = reactive(rulesObj);
rules = [];
const store = useStore(); //  vue 3.0 需要先引入useStore
const router = useRouter(); //
// const langObj = store.state.lang;
// console.log(langObj)
let encodeKey =
  getCurrentInstance().appContext.config.globalProperties.$encodeKey; // vue3不能从prototype获取
let cookieTime = getCurrentInstance().appContext.config.globalProperties.$time;
let authExpireTime =
  getCurrentInstance().appContext.config.globalProperties.$authExpireTime;
const submitForm = () => {
  ruleFormRef.value.validate(async (checkRes) => {
    if (checkRes) {
      let timeStamp = parseInt(new Date().getTime() / 1000);
      let md5Pwd = md5("TCK.W" + ruleForm.password + timeStamp);
      const data = {};
      data.time_stamp = timeStamp;
      data.user_name = ruleForm.username;
      data.user_pwd = md5Pwd;

      let res = await systemLogin(data);
      if (res.data.reply == 0) {
        let tckw_cookie = res.headers["tckw-cookie"];
        VueCookies.set("TCKWSESSID", tckw_cookie, cookieTime);
        VueCookies.set("timeStamp", timeStamp, cookieTime);
        VueCookies.set("auth", ruleForm.auth, authExpireTime);
        // store.commit("saveSessionidInfo", jwtToken); //存储到vue内部当中后面每个接口都要从内存获取
        ElMessage({
          message: "登录成功",
          type: "success",
          offset: 150,
          //  duration:2000,
          onClose: () => {
            router.push({
              name: "Home",
            });
          },
        });
        if (isPassword) {
          let encodeUsername = AES.encrypt(ruleForm.username, encodeKey);
          let encodePassword = AES.encrypt(ruleForm.password, encodeKey);
          VueCookies.set("username", encodeUsername, authExpireTime);
          VueCookies.set("password", encodePassword, authExpireTime);
        }
      } else {
        ElMessage({
          message: "账号或密码错误",
          type: "info",
          offset: 150,
        });
      }
    }
  });
};

const resetForm = () => {
  ruleFormRef.value.resetFields();
};

watch(
  () => ruleForm.auth,
  (auth) => {
    store.commit("saveStationAuth", auth);
    VueCookies.set("auth", auth);
  },
  { deep: true, immediate: true }
);

//需要计算属性去监听store.state.lang的变化

const langObj = computed(() => {
  return store.state.lang.list;
});

// watch(
//   () => store.state.lang,
//   (lang) => {
//       console.log(lang)
//   },
//   { deep: true, immediate: true }
// );

//此方法用来替代 vue2.0的 mounted

onMounted(() => {
  document.onkeydown = function (ev) {
    var event = ev || event;
    if (event.keyCode == 13) {
      submitForm("ruleForm");
    }
  };

  if (VueCookies.isKey("username") && VueCookies.isKey("password")) {
    ruleForm.username = AES.decrypt(VueCookies.get("username"), encodeKey);
    ruleForm.password = AES.decrypt(VueCookies.get("password"), encodeKey);
  }
});

// let http = getCurrentInstance().appContext.config.globalProperties.$axios; // vue3不能从prototype获取
</script>

<style lang="scss" scoped>
.container {
  background: url("../assets/backlist.jpg") no-repeat center center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center -0.05rem;
}

.main-container {
  color: red;
  height: 10rem;
}

.header {
  position: relative;
  width: 100%;

  .lang {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.login-container {
  // background-color: #01131d;
  box-shadow: 0 0 2px 1px #CCC inset;
  padding: 0.375rem;
  width: 6.25rem;
  margin-left: 1.25rem;
}

.login-box {
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
  color: #FFF;
  font-size: 0.35rem;
  font-weight: 600;
}

.logo-box {
  position: relative;
}

.logo-box img {
  position: absolute;
  width: 5.625rem;
  left: 50%;
  top: 0.625rem;
  transform: translate(-50%);
}

.form-box {
  padding-top: 2.5rem;
}

.button-box {
  display: flex;
  justify-content: center;
}

.button-box button {
  width: 1.25rem;
  height: 0.625rem;
  margin-right: 0.25rem;
  font-size: 0.2rem;
}

.form-box :deep .el-form-item__label {
  color: #fff;
  font-size: 0.2rem !important;
}

.form-box :deep .el-input__inner {
  font-size: 0.2rem !important;
}

.form-box :deep .el-form-item__content {
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  margin-left: 0;
}

.form-box :deep .el-form-item__error {
  font-size: 0.175rem;
}
</style>
